import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, reactive } from 'vue';
export default {
  __name: 'upload_FP',
  props: {
    // 上传额外需要携带的参数
    uploadData: {
      type: Object,
      default: function () {}
    }
  },
  emits: ['getresult'],

  setup(__props, {
    emit: __emit
  }) {
    const emits = __emit;
    const props = __props;
    const fileList = ref();
    const invoiceData = reactive({
      action: process.env.VUE_APP_BASE_API + '/rame/gsfms/uploadGSF_dzfp',
      // 上传的支票服务器地址 https://www.ipolarx.com/api/rame/gsfms/uploadGSF_dzfp
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }); // 上传文件成功回调

    const handleSuccess = uploadFile => {
      emits('getresult', uploadFile);
    }; // 上传文件失败回调


    const handleError = error => {
      emits('getresult', error);
    };

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_upload = _resolveComponent("el-upload");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_upload, {
        "file-list": fileList.value,
        "onUpdate:fileList": _cache[0] || (_cache[0] = $event => fileList.value = $event),
        class: "upload-demo",
        action: invoiceData.action,
        headers: invoiceData.headers,
        "on-success": handleSuccess,
        "on-error": handleError,
        limit: 1,
        data: props.uploadData,
        "on-exceed": _ctx.handleExceed,
        name: "GSF_dzfp"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("上传发票")]),
          _: 1
        })]),
        _: 1
      }, 8, ["file-list", "action", "headers", "data", "on-exceed"])]);
    };
  }

};