import request from './request'

// 服务站业绩-业绩占比统计 getEchartYJData
export const getFwzYJData = (data) => {
    return request.post('/rame/gsfms/yjgl/fwz/getYJData', data)
}
// 服务站业绩-订单总量 getEchartDDLData
export const getFwzDDLData = (data) => {
    return request.post('/rame/gsfms/yjgl/fwz/getDDLData', data)
}
// 服务站业绩/rame/gsfms/yjgl/fwz/zxt/getDDLData
export const getFwzOrderData = (data) => {
    return request.post('/rame/gsfms/yjgl/fwz/zxt/getDDLData', data)
}

// 派单员-订单总金额
export const getPdyPdlData = (data) => {
    return request.post('/rame/gsfms/yjgl/pdy/getYJData', data)
}

// 上门师傅-订单总金额
export const getWorkerPriceAll = (data) => {
    return request.post('/rame/gsfms/yjgl/sf/getYJData', data)
}
