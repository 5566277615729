import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-27a534a8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "steps"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "color": "red"
  }
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 0,
  style: {
    "color": "red"
  }
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "button"
};
const _hoisted_7 = {
  key: 4,
  class: "sendInvoice"
};
import { ref, reactive } from 'vue';
import { CircleCheck, CircleClose } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import { editInvoiceProgress } from '@/api/invoice';
import uploadFP from './upload_FP';
const formLabelWidth = '150px';
export default {
  __name: 'modal-examine',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const dialogVisible = ref(false);
    const emit = __emit;
    const formRef = ref();
    const InfoData = ref();
    const activevalue = ref(); // 步骤条的进度

    const uploadInvoiceData = ref({
      orderId: null,
      workerId: 2
    });
    const rules = ref({
      // 选项原因
      cause: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    });

    const generateFrom = () => {
      return {
        progress: null,
        // 开票进度：1 申请提交(成功) 2 商家审核(成功)  3 商家开票(成功) 4 商家审核(失败) 5 商家开票(失败)
        cause: [],
        // 如果点击【拒绝】，则为必填项，【审核通过】不必填写
        worker_remark: null,
        // 如果点击【拒绝】，则为选填项，【审核通过】不必填写
        orderId: null // 订单ID

      };
    };

    const formModel = reactive(generateFrom()); // 是否通榆审核

    const ISAuditReason = ref();

    const showModal = data => {
      formModel.cause = [];
      formModel.worker_remark = null;
      InfoData.value = data;
      activevalue.value = data.progress;
      formModel.orderId = data.orderId;
      formModel.progress = data.progress;
      uploadInvoiceData.value.orderId = data.orderId;
      ISAuditReason.value = true;

      if (data.progress === 4) {
        // 失败
        activevalue.value = 2;
      }

      if (data.progress === 5) {
        // 失败
        activevalue.value = 3;
      }

      dialogVisible.value = true;
    }; // 同意 确定


    const examineOK = () => {
      if (InfoData.value.progress === 1 || InfoData.value.progress === 4) {
        // 该审核和审核失败
        formModel.progress = 2;
        dialogVisible.value = false;
        formModel.cause = null;
        formModel.worker_remark = null;
        editProgress(formModel);
      } else if (InfoData.value.progress === 2 || InfoData.value.progress === 5) {// 该开票和开票失败
      }
    }; // 拒绝


    const examineNO = () => {
      ISAuditReason.value = false;
    }; // 返回


    const examineBack = () => {
      ISAuditReason.value = true;
    }; // 拒绝时的确定


    const NOexamineOK = async () => {
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');

          if (InfoData.value.progress === 1 || InfoData.value.progress === 4) {
            // 拒绝审核
            formModel.progress = 4;
            editProgress(formModel);
          } else if (InfoData.value.progress === 2 || InfoData.value.progress === 5) {
            // 拒绝开票
            formModel.progress = 5;
            editProgress(formModel);
          }
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    const editProgress = async data => {
      if (data.cause) {
        data.cause = Object.values(data.cause).join(',');
      }

      const resData = await editInvoiceProgress(data);

      if (resData && resData.status === 'success') {
        ElMessage({
          message: '编辑成功！',
          type: 'success'
        });
        emit('refresh');
        dialogVisible.value = false;
      } else {
        ElMessage({
          message: '编辑失败',
          type: 'error'
        });
      }
    }; // 获取发票的回调


    const getresult = e => {
      if (e.status === 'success') {
        // 关闭弹窗
        dialogVisible.value = false; // 传递给页面刷新

        emit('refresh');
      } else {
        ElMessage({
          message: '上传发票失败',
          type: 'error'
        });
      }
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_step = _resolveComponent("el-step");

      const _component_el_steps = _resolveComponent("el-steps");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dialogVisible.value = $event),
        title: "审核",
        width: "50%",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_steps, {
          style: {
            "width": "800px"
          },
          active: activevalue.value,
          "align-center": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_step, {
            title: "申请提交"
          }, {
            icon: _withCtx(() => [activevalue.value >= 1 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: "26"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleCheck))]),
              _: 1
            })) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_step, null, _createSlots({
            title: _withCtx(() => [InfoData.value.progress == 4 ? (_openBlock(), _createElementBlock("text", _hoisted_2, "服务站审核")) : (_openBlock(), _createElementBlock("text", _hoisted_3, "服务站审核"))]),
            _: 2
          }, [activevalue.value >= 2 ? {
            name: "icon",
            fn: _withCtx(() => [InfoData.value.progress == 4 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: "26",
              color: "red"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleClose))]),
              _: 1
            })) : activevalue.value >= 2 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              size: "26"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleCheck))]),
              _: 1
            })) : _createCommentVNode("", true)]),
            key: "0"
          } : undefined]), 1024), _createVNode(_component_el_step, null, _createSlots({
            title: _withCtx(() => [InfoData.value.progress == 5 ? (_openBlock(), _createElementBlock("text", _hoisted_4, "服务站开票")) : (_openBlock(), _createElementBlock("text", _hoisted_5, "服务站开票"))]),
            _: 2
          }, [activevalue.value >= 3 ? {
            name: "icon",
            fn: _withCtx(() => [InfoData.value.progress == 5 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: "26",
              color: "red"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleClose))]),
              _: 1
            })) : activevalue.value >= 3 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              size: "26"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleCheck))]),
              _: 1
            })) : _createCommentVNode("", true)]),
            key: "0"
          } : undefined]), 1024)]),
          _: 1
        }, 8, ["active"])]), _createElementVNode("div", _hoisted_6, [ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "danger",
          onClick: examineNO
        }, {
          default: _withCtx(() => [_createTextVNode(" 拒绝 ")]),
          _: 1
        })) : _createCommentVNode("", true), ISAuditReason.value && InfoData.value.progress == 1 || ISAuditReason.value && InfoData.value.progress == 4 ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "success",
          onClick: examineOK
        }, {
          default: _withCtx(() => [_createTextVNode(" 确认 ")]),
          _: 1
        })) : _createCommentVNode("", true), !ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_button, {
          key: 2,
          type: "info",
          onClick: examineBack
        }, {
          default: _withCtx(() => [_createTextVNode(" 返回 ")]),
          _: 1
        })) : _createCommentVNode("", true), !ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_button, {
          key: 3,
          type: "success",
          onClick: NOexamineOK
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })) : _createCommentVNode("", true), ISAuditReason.value && InfoData.value.progress == 2 || ISAuditReason.value && InfoData.value.progress == 5 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_unref(uploadFP), {
          uploadData: uploadInvoiceData.value,
          onGetresult: getresult
        }, null, 8, ["uploadData"])])) : _createCommentVNode("", true)]), !ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_form, {
          key: 0,
          model: formModel,
          rules: rules.value,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "拒绝原因",
                prop: "cause",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
                  modelValue: formModel.cause,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.cause = $event),
                  class: "AuditReasoncheckbox"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_checkbox, {
                    label: "原因1",
                    value: "原因1"
                  }), _createVNode(_component_el_checkbox, {
                    label: "原因2",
                    value: "原因2"
                  }), _createVNode(_component_el_checkbox, {
                    label: "原因3",
                    value: "原因3"
                  })]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "操作人员备注",
                prop: "worker_remark",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_input, {
                  modelValue: formModel.worker_remark,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.worker_remark = $event),
                  style: {
                    "width": "600px"
                  },
                  rows: 2,
                  type: "textarea",
                  placeholder: "请输入"
                }, null, 8, ["modelValue"])])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};