import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { nextTick, onMounted, onUnmounted, ref, shallowRef, watch, watchEffect } from 'vue';
import * as echarts from 'echarts'; // props

export default {
  __name: 'index',
  props: {
    option: {
      type: [Object, null],
      require: true
    },
    // 配置项
    width: {
      type: String,
      default: '300px'
    },
    height: {
      type: String,
      default: '200px'
    },
    className: {
      type: String,
      default: 'cl-chartCom'
    },
    loading: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function
    }
  },

  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    const container = ref(null); // 容器元素

    const instance = shallowRef(null); // 实例

    const resizeObserver = shallowRef(null); // 元素尺寸侦听器

    const debounceTimer = ref(null); // 防抖计时器id
    // 初始化

    function init() {
      if (!container.value) return; // 查看容器上是否已经挂载了echarts实例 , 如果已挂载就获取并使用该实例,如果未挂载就进行初始化

      instance.value = echarts.getInstanceByDom(container.value);

      if (!instance.value) {
        instance.value = echarts.init(container.value);
      } // 绘制图表


      draw(); // 侦听图表事件

      addListeningChartEvent();
    } // 绘制图表


    function draw() {
      if (!props.option || !instance.value) return;
      instance.value.setOption(props.option, {
        notMerge: true
      });
    } // 图表自适应
    // 重置图表尺寸


    function resize() {
      clearTimeout(debounceTimer.value);
      debounceTimer.value = setTimeout(() => {
        var _instance$value;

        // eslint-disable-next-line no-unused-expressions
        (_instance$value = instance.value) === null || _instance$value === void 0 ? void 0 : _instance$value.resize({
          animation: {
            duration: 300
          }
        });
        debounceTimer.value = null;
      }, 300);
    }

    resizeObserver.value = new ResizeObserver(resize); // 重绘图表

    watch(props, () => {
      nextTick(() => {
        draw();
      });
    }); // loading动画

    watchEffect(() => {
      var _instance$value2, _instance$value3;

      return props.loading ? (_instance$value2 = instance.value) === null || _instance$value2 === void 0 ? void 0 : _instance$value2.showLoading() : (_instance$value3 = instance.value) === null || _instance$value3 === void 0 ? void 0 : _instance$value3.hideLoading();
    }); // echarts事件绑定

    function addListeningChartEvent() {
      var _instance$value4;

      // 点击事件
      // eslint-disable-next-line no-unused-expressions
      props.onClick && ((_instance$value4 = instance.value) === null || _instance$value4 === void 0 ? void 0 : _instance$value4.on('click', 'series', e => {
        props.onClick(e, instance.value, props.option);
      }));
    }

    onMounted(() => {
      nextTick(() => {
        init();
        resizeObserver.value.observe(container.value);
      });
    });
    onUnmounted(() => {
      //   instance.value?.dispose()
      //   resizeObserver.value?.disconnect()
      resizeObserver.value = null;
      clearTimeout(debounceTimer.value);
      debounceTimer.value = null;
    });

    __expose({
      getInstance: () => instance.value,
      resize,
      draw
    });

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "container",
        ref: container,
        class: _normalizeClass(props.className),
        style: _normalizeStyle({
          width: props.width,
          height: props.height
        })
      }, null, 6);
    };
  }

};