import request from './request'
// 发票

// 查看发票
export const getInvoiceLists = (data) => {
    return request.post('/rame/gsfms/getGSF_fp', data)
}

// 修改发票 /rame/gsfms/updateGSF_fp
export const editInvoice = (data) => {
    return request.post('/rame/gsfms/updateGSF_fp', data)
}

// 修改开票进度
export const editInvoiceProgress = (data) => {
    return request.post('/rame/gsfms/updateGSF_fp_progress', data)
}

// 上传发票/rame/gsfms/uploadGSF_dzfp
export const uploadInvoice = (data) => {
    return request.post('/rame/gsfms/uploadGSF_dzfp', data)
}

// 发送发票
export const sendInvoice = (data) => {
    return request.post('/rame/gsfms/send_dzfp', data)
}
